import API from 'api';

export const takeQuiz = async (input: {
  quizId: string;
  token: string;
}): Promise<Types.API.QuizTaken.IQuizTakenModel> => {
  const response = await API.post(
    '/students/takeQuiz',
    { quizId: input.quizId },
    { headers: { Authorization: 'Bearer ' + input.token }, params: { quizId: input.quizId } },
  );
  return response.data.quiz;
};
export const takeHomework = async (input: {
  quizId: string;
  token: string;
}): Promise<Types.API.QuizTaken.IQuizTakenModel> => {
  const response = await API.post(
    '/students/takeHomework',
    { quizId: input.quizId },
    { headers: { Authorization: 'Bearer ' + input.token }, params: { quizId: input.quizId } },
  );
  return response.data.homeworkTaken;
};

export const getStudentTreeViews = async (input: { token: string }): Promise<Types.API.TreeView.ITreeViewInfo[]> => {
  const response = await API.get('/students/getTreeViews', {
    headers: { Authorization: 'Bearer ' + input.token },
    params: { limit: 10000 },
  });
  return response.data.treeViews;
};

export const getAvailableQuizzes = async (input: {
  token: string;
}): Promise<{
  quizzes: Types.API.Quiz.IQuizInfo[];
  expiredQuizzes: Types.API.Quiz.IQuizInfo[];
  runningQuizzes: Types.API.QuizTaken.IQuizTakenInfo[];
  finishedQuizzes: Types.API.QuizTaken.IQuizTakenInfo[];
  gradePublishedQuizzes: Types.API.QuizTaken.IQuizTakenInfo[];
}> => {
  const response = await API.get('/students/getAvailableQuizzes', {
    headers: { Authorization: 'Bearer ' + input.token },
    params: { limit: 10000 },
  });
  return {
    quizzes: response.data.quizzes,
    expiredQuizzes: response.data.expiredQuizzes,
    runningQuizzes: response.data.runningQuizzes,
    finishedQuizzes: response.data.finishedQuizzes,
    gradePublishedQuizzes: response.data.gradePublishedQuizzes,
  } as {
    quizzes: Types.API.Quiz.IQuizInfo[];
    expiredQuizzes: Types.API.Quiz.IQuizInfo[];
    runningQuizzes: Types.API.QuizTaken.IQuizTakenInfo[];
    finishedQuizzes: Types.API.QuizTaken.IQuizTakenInfo[];
    gradePublishedQuizzes: Types.API.QuizTaken.IQuizTakenInfo[];
  };
};
export const getAvailableHomeworks = async (input: {
  token: string;
}): Promise<{
  homeworks: Types.API.Quiz.IQuizInfo[];
  expiredHomeworks: Types.API.Quiz.IQuizInfo[];
  runningHomeworks: Types.API.QuizTaken.IQuizTakenInfo[];
  finishedHomeworks: Types.API.QuizTaken.IQuizTakenInfo[];
  gradePublishedHomeworks: Types.API.QuizTaken.IQuizTakenInfo[];
}> => {
  const response = await API.get('/students/getAvailableHomeworks', {
    headers: { Authorization: 'Bearer ' + input.token },
    params: { limit: 10000 },
  });
  return {
    homeworks: response.data.homeworks,
    expiredHomeworks: response.data.expiredHomeworks,
    runningHomeworks: response.data.runningHomeworks,
    finishedHomeworks: response.data.finishedHomeworks,
    gradePublishedHomeworks: response.data.gradePublishedHomeworks,
  };
};

export const mutateStudentProgress = async (input: {
  videoId: string;
  studentId: string;
  grade: number;
  index: number;
  length: number;
  token: string;
}) => {
  try {
    const { studentId, length, grade, index, token, videoId } = input;
    const response = await API.put(
      `/students/mutateStudentProgress`,
      { videoId, studentId, length, grade, index },
      {
        headers: { Authorization: 'Bearer ' + token },
      },
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
