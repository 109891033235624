import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import studentStyle from 'assets/jss/lms/features/student';
import QuizInfoCard from './QuizInfoCard';
import { Divider, makeStyles, Typography } from '@material-ui/core';
import VideoCard from 'components/Video/VideoCard';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface Props {
  monthDataPair: {};
  sortedMonths: string[];
  type: 'running' | 'available' | 'published' | 'late' | 'vdo-lect' | 'vdo-speaking'; // in progress | new | finished | late
}

const useStudentStyles = makeStyles(() => studentStyle);

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const MonthContent: React.FC<Props> = ({ type, monthDataPair, sortedMonths }) => {
  const classes = useStudentStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const contentCards = (month: string) => {
    return (
      <div>
        <Typography variant="h6">{month}</Typography>
        <div className={classes.quizzesContainer}>
          {monthDataPair[month].map((q: any) => {
            return (
              <div className={classes.quizItem} key={q.id}>
                {type === 'vdo-lect' || type === 'vdo-speaking' ? (
                  <VideoCard
                    key={q._id}
                    id={q._id}
                    url={q.url}
                    title={q.title}
                    description={q.description}
                    theme={q.theme}
                    vdoCipherId={q.vdoCipherId}
                    otpObj={q.otpObj}
                    posterImgUrl={q.posterImgUrl}
                    groups={q.groups}
                    assignedDate={q.assignedDate || q.createdAt}
                    subtitles={q.subtitles}
                    type={q.type}
                  />
                ) : (
                  <QuizInfoCard
                    key={q.id}
                    id={q.id}
                    name={q.name}
                    quizCardType={type}
                    dueDate={q.dueDate}
                    isHomework={q.isHomework}
                    quizDuration={q.durationInMinutes}
                    isLate={q.isLate}
                    answers={q.answers}
                  />
                )}
              </div>
            );
          })}
          <Divider style={{ marginTop: '40px' }} />
        </div>
      </div>
    );
  };

  const setTabsTitle = () => {
    return sortedMonths.map((month, i) => {
      return <Tab label={month} {...a11yProps(i)} />;
    });
  };

  const setTabContent = () => {
    return sortedMonths.map((month, i) => {
      return (
        <TabPanel value={value} index={i} key={month}>
          {contentCards(month)}
        </TabPanel>
      );
    });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
        >
          {setTabsTitle()}
        </Tabs>
      </Box>

      {setTabContent()}
    </Box>
  );
};

export default MonthContent;
