import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from 'features/auth/authSlice';
import { useReqListener } from 'hooks/useReqListener';

import {
  getVdoCipherVideosReqSelector,
  getVdoCipherVideosSelector,
  getVdoCipherAsync,
  setCreateVdoCipherInputs,
} from '../slices/adminSlice';
import { RootState } from 'features';
import CustomInput from 'components/CustomInput/CustomInput';
import styles from 'assets/jss/lms/features/questions';
import { makeStyles, Typography } from '@material-ui/core';
import Box from '@mui/material/Box';
import classNames from 'classnames';
import Autocomplete, { OptionType } from 'components/Autocomplete/Autocomplete';
import GridContainer from 'components/Grid/GridContainer';
import MonthContent from 'features/student/MonthContent';
import { formateMonthDataPair, sortMonths } from 'features/helpers';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CustomCircularProgress from 'components/Progress/CustomCircularProgress';
import Container from '@mui/material/Container';
import CustomLinearProgress from 'components/Progress/CustomLinearProgress';
import VideoForm from './VideoForm';
import { FormControlLabel, Grid, Switch } from '@mui/material';
import CustomButton from 'components/CustomButtons/Button';
import studentStyle from 'assets/jss/lms/features/student';
import VideoCard from 'components/Video/VideoCard';

const useStyles = makeStyles(styles);
const useStudentStyles = makeStyles(() => studentStyle);

export const themes: OptionType[] = [
  // { id: 'all', name: 'All Themes' },
  { id: 'th1', name: 'Theme 1' },
  { id: 'th2', name: 'Theme 2' },
  { id: 'th3', name: 'Theme 3' },
];

export const ThemeQuestions: OptionType[] = [
  // Theme 1 Questions
  { id: 'th1-q1', name: 'Q1. What is your ideal family?' },
  { id: 'th1-q2', name: 'Q2. What do you like doing with your family?' },
  { id: 'th1-q3', name: 'Q3. What have you done with your friends recently?' },
  { id: 'th1-q4', name: 'Q4. How do you use social media to keep in contact with your friends?' },
  { id: 'th1-q5', name: 'Q5. What are the best uses mobile technology?' },
  { id: 'th1-q6', name: 'Q6. How does music enrich the lives of people?' },
  { id: 'th1-q7', name: 'Q7. What is the best film you have seen at the cinema? Why?' },
  { id: 'th1-q8', name: 'Q8. Why do you think so many people like to play sport?' },
  { id: 'th1-q9', name: 'Q9. How do you celebrate your birthday?' },
  { id: 'th1-q10', name: 'Q10. Why is it important to continue local customs?' },

  // Theme 2 Questions
  { id: 'th2-q1', name: 'Q1. What is there for young people to do in the area where you live?' },
  { id: 'th2-q2', name: 'Q2. How have you helped a neighbour in the past?' },
  { id: 'th2-q3', name: 'Q3. Which charity do you think most deserves support? Why?' },
  { id: 'th2-q4', name: 'Q4. What are the best ways to keep fit and healthy?' },
  { id: 'th2-q5', name: 'Q5. Why is it important that people eat healthily?' },
  { id: 'th2-q6', name: 'Q6. What are the main problems faced by homeless people?' },
  { id: 'th2-q7', name: 'Q7. What are the effects of global warming?' },
  { id: 'th2-q8', name: 'Q8. Why do you think so many people like to visit other countries?' },
  { id: 'th2-q9', name: 'Q9. Where have you been on holiday?' },
  { id: 'th2-q10', name: 'Q10. What is your ideal holiday? Why?' },

  // Theme 3 Questions
  { id: 'th3-q1', name: 'Q1. Which subject do you most enjoy studying? Why?' },
  { id: 'th3-q2', name: 'Q2. Tell me about a something that you have enjoyed at school.' },
  { id: 'th3-q3', name: 'Q3. Explain why it is important to learn other languages.' },
  { id: 'th3-q4', name: 'Q4. What could be done to improve your life in school/college?' },
  { id: 'th3-q5', name: 'Q5. In your opinion, what makes a good teacher?' },
  { id: 'th3-q6', name: 'Q6. What do you plan to study next year?' },
  { id: 'th3-q7', name: 'Q7. What could be the advantages of going to university?' },
  { id: 'th3-q8', name: 'Q8. Would you like to work in England? Why/why not?' },
  { id: 'th3-q9', name: 'Q9. Why is it important to have a good job?' },
  { id: 'th3-q10', name: 'Q10. Describe your ideal job' },
];

interface Props {}
const VimVideoSpeakManagement: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const studentClasses = useStudentStyles();
  const token = useSelector(tokenSelector);
  const vdoCipherVideos = useSelector(getVdoCipherVideosSelector);
  const createVdoCipherInputs = useSelector((state: RootState) => state.admin.createVdoCipher.videoInputs);

  const vdoCipherVideosReq = useSelector(getVdoCipherVideosReqSelector);
  const uploadVdoCipherReq = useSelector((state: RootState) => state.admin.uploadUsingCredentials.req);
  const createVdoCipherReq = useSelector((state: RootState) => state.admin.createVdoCipher.req);
  const editVdoCipherReq = useSelector((state: RootState) => state.admin.editVdoCipherReq);
  const deleteVdoCipherReq = useSelector((state: RootState) => state.admin.deleteVdoCipherReq);

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleExpansion = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [openAddDialogue, setOpenAddDialogue] = React.useState(false);

  const videoTypeFilter = { id: 'vdo-speaking', name: 'Video Speaking' };

  const [selectedTheme, setSelectedTheme] = React.useState<OptionType>({ id: 'all', name: 'All Themes' });
  const [selectedQuestion, setSelectedQuestion] = React.useState<OptionType>({ id: 'all', name: 'All Questions' });

  const [groupByMonth, setGroupByMonth] = React.useState<boolean>(true);
  /* const videoTypes: OptionType[] = [
    { id: 'vdo-lect', name: 'Video Lecture' },
    { id: 'vdo-speaking', name: 'Video Speaking' },
  ];
 */
  // vdo-speaking videos has vdo-speaking videos
  // not vdo-speaking videos has vdo-lect videos and undefined type videos
  const filteredVideos = vdoCipherVideos.filter(
    v =>
      v.type === 'vdo-speaking' &&
      (selectedTheme?.id === 'all' || (v.theme && v.theme.startsWith(selectedTheme.id))) &&
      (selectedQuestion?.id === 'all' || (v.theme && v.theme === selectedQuestion.id)),
  );

  useReqListener({
    status: vdoCipherVideosReq.status,
    successMessage: 'Successfully fetched all videos',
    loadingMessage: 'Fetching videos the DB',
    errorMessage: vdoCipherVideosReq.error,
  });
  useReqListener({
    status: editVdoCipherReq.status,
    successMessage: 'Successfully Edited the Video',
    loadingMessage: 'Editing the video',
    errorMessage: editVdoCipherReq.error,
  });
  useReqListener({
    status: deleteVdoCipherReq.status,
    successMessage: 'Successfully Deleted the Video',
    loadingMessage: 'Deleting the video',
    errorMessage: deleteVdoCipherReq.error,
  });

  React.useEffect(() => {
    token && vdoCipherVideosReq.status === 'idle' && dispatch(getVdoCipherAsync({ token }));
  }, [token, vdoCipherVideosReq.status, dispatch]);

  React.useEffect(() => {
    token && uploadVdoCipherReq.status == 'succeeded' && dispatch(getVdoCipherAsync({ token }));
  }, [token, uploadVdoCipherReq.status, dispatch]);

  React.useEffect(() => {
    token && editVdoCipherReq.status == 'succeeded' && dispatch(getVdoCipherAsync({ token }));
  }, [token, editVdoCipherReq.status, dispatch]);

  React.useEffect(() => {
    token && deleteVdoCipherReq.status == 'succeeded' && dispatch(getVdoCipherAsync({ token }));
  }, [token, deleteVdoCipherReq.status, dispatch]);

  if (vdoCipherVideosReq.status === 'loading')
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CustomCircularProgress />
      </Box>
    );

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <div className={classNames(classes.container, classes.categoryAutocomplete)}>
        <GridContainer
          spacing={3}
          style={{
            margin: 'auto',
          }}
        >
          <Grid item>
            <CustomButton onClick={() => setOpenAddDialogue(true)} color="primary">
              Add New Video with Speak the lines
            </CustomButton>
            <VideoForm
              openDialogue={openAddDialogue}
              setOpenDialogue={setOpenAddDialogue}
              edit={false}
              type="vdo-speaking"
            />
          </Grid>

          {/*           <Grid width={500} item>
            <Autocomplete
              labelText="Filter Video Types"
              id="type"
              value={videoTypeFilter}
              onChange={(_e, value: OptionType) => setVideoTypeFilter(value)}
              options={videoTypes}
            />
          </Grid> */}
        </GridContainer>
      </div>

      <div style={{ marginBottom: '20px' }} className={classNames(classes.container, classes.categoryAutocomplete)}>
        <Autocomplete
          labelText="Theme"
          id="theme"
          disableClearable
          value={selectedTheme}
          onChange={(_e, value: OptionType) => {
            setSelectedTheme(value);
            value.id === 'all' && setSelectedQuestion({ id: 'all', name: 'All Questions' });
          }}
          options={[{ id: 'all', name: 'All Themes' }].concat(themes)}
        />
        <Autocomplete
          labelText="Question"
          id="question"
          disableClearable
          value={selectedQuestion}
          onChange={(_e, value: OptionType) => setSelectedQuestion(value)}
          options={ThemeQuestions.filter(q => q.id.startsWith(selectedTheme.id))}
        />
      </div>

      <div style={{ marginBottom: '70px', textAlign: 'center' }} className={classNames(classes.container)}>
        <FormControlLabel
          control={
            <Switch
              checked={groupByMonth}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setGroupByMonth(event.target.checked);
              }}
            />
          }
          label="Group By Month"
        />
      </div>

      {uploadVdoCipherReq.status === 'loading' && (
        <Container sx={{ mb: 10 }}>
          <CustomLinearProgress />
          <Typography style={{ display: 'flex', justifyContent: 'center' }} variant="subtitle2">
            Please wait while uploading the video .. Don't refresh the page
          </Typography>
        </Container>
      )}

      <Card>
        <CardHeader color="info" className={classes.cardHeader}>
          <h6 style={{ fontWeight: 'bolder', fontSize: 'large' }}>All Speaking Videos</h6>
        </CardHeader>

        <CardBody>
          {!groupByMonth ? (
            <div className={studentClasses.quizzesContainer}>
              {filteredVideos.map((v: any) => (
                <div className={studentClasses.quizItem} key={v._id}>
                  <VideoCard
                    key={v._id}
                    id={v._id}
                    url={v.url}
                    title={v.title}
                    description={v.description}
                    theme={v.theme}
                    vdoCipherId={v.vdoCipherId}
                    otpObj={v.otpObj}
                    posterImgUrl={v.posterImgUrl}
                    groups={v.groups}
                    assignedDate={v.assignedDate || v.createdAt}
                    subtitles={v.subtitles}
                    type={v.type}
                  />
                </div>
              ))}
            </div>
          ) : (
            <MonthContent
              type={videoTypeFilter.id === 'vdo-lect' ? 'vdo-lect' : 'vdo-speaking'}
              monthDataPair={formateMonthDataPair(filteredVideos, videoTypeFilter.id)}
              sortedMonths={sortMonths(formateMonthDataPair(filteredVideos, videoTypeFilter.id))}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default VimVideoSpeakManagement;
