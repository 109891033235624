import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import useVdoCipherNew from 'hooks/useVdoCipherNew';
import { parseSubtitle } from 'features/helpers';
import SpeakLinesModal from 'components/Video/SpeakLinesModal';
import './style.css';

interface VdoPlayer {
  video: HTMLVideoElement;
  api: any;
}

declare global {
  interface Window {
    VdoPlayer: any;
    player: VdoPlayer;
  }
}

interface VideoPlayerProps {
  otp: string;
  playbackInfo: string;
  videoState: 'watch' | 'speak' | 'learn' | undefined;
  // handleSpeak: (sentence: string) => void;
}

export interface ISubtitle {
  text: string;
  start: number;
  end: number;
}

export enum Status {
  Playing = 'Playing',
  Paused = 'Paused',
  Ready = 'Ready',
  NA = 'NA',
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ otp, playbackInfo, videoState }) => {
  const { loadVideo, isAPIReady } = useVdoCipherNew();
  const videoContainerRef = useRef<HTMLDivElement | null>(null);
  const [videoRef, setVideoRef] = useState<HTMLIFrameElement | null>(null);

  const [status, setStatus] = useState<Status>(Status.NA);
  const [player, setPlayer] = useState<VdoPlayer | null>(null);
  //   const [currentTime, setCurrentTime] = useState(0);
  //   const [metaData, setMetaData] = useState<any | undefined>(undefined);
  //   const [subtitleUrl, setSubtitleUrl] = useState<string | undefined>(undefined);
  //   const [subtitleString, setSubtitleString] = useState<string | undefined>(undefined);
  //   const [subtitles, setSubtitles] = useState<ISubtitle[] | undefined>(undefined);
  const subtitlesRef = useRef<ISubtitle[]>([]);
  const currentSubtitleIndexRef = useRef(0);
  //   console.log('🚀 ~ file: VideoPlayer.tsx:47 ~ currentSubtitleIndexRef:', currentSubtitleIndexRef.current);
  //   console.log('🚀 ~ file: VideoPlayer.tsx:46 ~ subtitlesRef:', subtitlesRef.current);

  const [textToSpeak, setTextToSpeak] = useState<string | undefined>(undefined);
  const [open, setOpen] = useState(false);

  const handleSpeak = (sentence: string) => {
    setTextToSpeak(sentence);
    setOpen(true);
  };

  const handleNext = () => {
    console.log('🚀handleNext:');
    setOpen(false);
    currentSubtitleIndexRef.current += 1;
    player?.video.play();
  };

  const handleSkip = () => {
    console.log('🚀handleSkip:');
    setOpen(false);
    currentSubtitleIndexRef.current += 1;
    // setCurrentSubtitleIndexRef();
  };

  const setCurrentSubtitleIndexRef = () => {
    if (!player) return;
    if (videoState !== 'speak') return;
    const myCurrentTime = player.video.currentTime;
    const index = subtitlesRef.current.findIndex(
      subtile => myCurrentTime >= subtile.start && myCurrentTime <= subtile.end,
    );
    currentSubtitleIndexRef.current = index;
  };

  useEffect(() => {
    if (!isAPIReady) return;

    const video = loadVideo({
      otp,
      playbackInfo,
      container: videoContainerRef.current,
      configuration: { litemode: true },
    });
    setVideoRef(video);

    const customPlayer = new window.VdoPlayer(video) as VdoPlayer;
    window.player = customPlayer;
    setPlayer(customPlayer);

    const setSubtitleStates = async () => {
      try {
        const meta = await customPlayer.api.getMetaData();
        const mySubtitleUrl = meta.captions[0]?.url || undefined;
        if (!mySubtitleUrl) return;
        const mySubtitleResponse = await axios.get(mySubtitleUrl);
        const mySubtitleString = mySubtitleResponse.data;
        const mySubtitles = parseSubtitle(mySubtitleString);

        // setMetaData(meta);
        // setSubtitleUrl(mySubtitleUrl);
        // setSubtitleString(mySubtitleString);
        // setSubtitles(mySubtitles);
        subtitlesRef.current = mySubtitles;
      } catch (error) {
        console.log('🚀 ~ file: VideoPlayer.tsx:122 ~ setSubtitleStates ~ error:', error);
      }
    };

    setSubtitleStates();

    return () => {
      setVideoRef(null);
      setPlayer(null);
      subtitlesRef.current = [];
    };
  }, [isAPIReady]);

  useEffect(() => {
    if (!player) return;

    const handleTimeUpdate = () => {
      //   console.log('🚀 handleTimeUpdate Outside:');
      if (currentSubtitleIndexRef.current === -1) return;
      if (!subtitlesRef.current || subtitlesRef.current.length === 0) return;
      if (videoState !== 'speak') return;
      console.log('🚀 timeupdate Inside:');
      const myCurrentTime = player.video.currentTime;
      const currentSubtitleIndex = currentSubtitleIndexRef.current;
      const minBoundary = subtitlesRef.current[currentSubtitleIndex].end;
      const maxBoundary = subtitlesRef.current[currentSubtitleIndex + 1].start;
      const delay = 0.28;
      const subtitleText = subtitlesRef.current[currentSubtitleIndex].text;

      if (
        currentSubtitleIndex < subtitlesRef.current.length &&
        myCurrentTime > minBoundary - delay &&
        myCurrentTime < maxBoundary + delay
        // myCurrentTime < minBoundary + delay
      ) {
        player.video.pause();
        handleSpeak(subtitleText);
        // currentSubtitleIndexRef.current += 1;
      }
    };

    player.video.addEventListener('play', () => {
      console.log('🚀 play:');
      setStatus(Status.Playing);
      //   setCurrentSubtitleIndexRef();
      // currentSubtitleIndexRef.current += 1;
    });
    player.video.addEventListener('pause', () => setStatus(Status.Paused));
    player.video.addEventListener('canplay', () => setStatus(Status.Ready));
    player.video.addEventListener('timeupdate', handleTimeUpdate);
    player.video.addEventListener('seeked', () => {
      console.log('🚀 seeked:');
      setCurrentSubtitleIndexRef();
    });

    return () => {
      player.video.removeEventListener('timeupdate', handleTimeUpdate);
      currentSubtitleIndexRef.current = -1;
      player.video.currentTime = 0;
    };
  }, [player]);

  return (
    <div className="vdo-container" ref={videoContainerRef}>
      {/* <SpeakLinesModal
        textToSpeak={textToSpeak}
        type="video"
        open={open}
        setOpen={setOpen}
        handlePrimaryAction={handleNext}
        handleSecondaryAction={handleSkip}
      /> */}

      <SpeakLinesModal
        isOpen={open}
        subtitle={textToSpeak}
        subtitleIndex={currentSubtitleIndexRef.current}
        totalSubtitles={subtitlesRef.current.length}
        onSubmit={handleNext}
      />
    </div>
  );
};

export default VideoPlayer;
