import React, { useEffect } from 'react';
import Validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
// @material-ui/icons
import Email from '@material-ui/icons/Email';
// core components
import Header from 'components/Header/Header';
import HeaderLinks from 'components/Header/HeaderLinks';
import Footer from 'components/Footer/Footer';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardFooter from 'components/Card/CardFooter';
import CustomInput from 'components/CustomInput/CustomInput';
import Notification from 'features/notifications/Notification';

import styles from 'assets/jss/lms/views/landing/login';

import image from 'assets/img/bg7.jpg';
import {
  forgotPasswordAsync,
  forgotPasswordReqSelector,
  loggedInSelector,
  resetCodeSelector,
  resetPasswordAsync,
  resetPasswordSelector,
  sendResetCodeAsync,
  statusSelector,
  userSelector,
} from 'features/auth/authSlice';

import { loginAsync } from 'features/auth/authSlice';
import { Link, useHistory } from 'react-router-dom';
import { useInputForm, useReqListener } from 'hooks';

const useStyles = makeStyles(styles);
const isEmail = (em: string) => Validator.isEmail(em);

const ForgotPasswordView: React.FC = props => {
  const [cardAnimation, setCardAnimation] = React.useState('cardHidden');
  const [processState, setProcessState] = React.useState(0);
  const [emailState, onEmailFormChange] = useInputForm({ validationFunction: isEmail });
  const [codeState, onCodeFormChange] = useInputForm({});
  const [passNewState, onPassNewFormChange] = useInputForm({});
  const [passConfirmState, onPassConfirmFormChange] = useInputForm({});

  const forgotPasswordReq = useSelector(forgotPasswordReqSelector);
  const user = useSelector(userSelector);
  const isLoggedIn = useSelector(loggedInSelector);
  const resetCode = useSelector(resetCodeSelector);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleSendResetCode = () => {
    dispatch(sendResetCodeAsync({ email: emailState.inputValue }));
    setProcessState(1);
  };

  const handleConfirmResetCode = () => {
    if (resetCode === codeState.inputValue) setProcessState(2);
  };

  const handleForgotPassword = () => {
    dispatch(
      forgotPasswordAsync({
        email: emailState.inputValue,
        newPassword: passNewState.inputValue,
        confirmPassword: passConfirmState.inputValue,
      }),
    );
  };
  useEffect(() => {
    setTimeout(function () {
      setCardAnimation('');
    }, 300);
  }, []);

  useReqListener({
    status: forgotPasswordReq.status,
    successMessage: 'Password Changed Successfully',
    loadingMessage: 'Changing password',
    errorMessage: forgotPasswordReq.error,
  });

  useEffect(() => {
    if (isLoggedIn) history.push('/' + user.role);
  }, [user.role, isLoggedIn, history]);

  useEffect(() => {
    if (forgotPasswordReq.status === 'succeeded') history.push('/landing/login');
  }, [forgotPasswordReq.status, history]);

  const classes = useStyles();

  const sendCodeForm = () => {
    return (
      <>
        <p style={{ paddingLeft: '40px', paddingRight: '40px', textAlign: 'center' }}>
          Please enter your email address to receive a password reset code.
        </p>

        <CardBody>
          <CustomInput
            labelText="Email..."
            id="email"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: 'email',
              endAdornment: (
                <InputAdornment position="end">
                  <Email className={classes.inputIconsColor} />
                </InputAdornment>
              ),
              required: true,
              autoFocus: true,
              onChange: onEmailFormChange,
              value: emailState.inputValue,
              error: emailState.error,
            }}
          />
        </CardBody>

        <CardFooter className={classes.cardFooter} style={{ paddingBottom: 0 }}>
          <Button disabled={emailState.error} onClick={handleSendResetCode} color="primary" size="lg">
            Send Code
          </Button>
        </CardFooter>

        <CardFooter className={classes.cardFooter}>
          <Button color="primary" size="lg" simple>
            <Link to="/landing/login">Back to Login</Link>
          </Button>
        </CardFooter>
      </>
    );
  };

  const verifyCodeForm = () => {
    return (
      <>
        <p style={{ paddingLeft: '40px', paddingRight: '40px', textAlign: 'center' }}>
          Please check your email for a 6-digit code. If you don't see it, check your spam folder.
        </p>

        <CardBody>
          <CustomInput
            labelText="Rest Code..."
            id="rest-code"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: 'text',
              endAdornment: (
                <InputAdornment position="end">
                  <Icon className={classes.inputIconsColor} />
                </InputAdornment>
              ),
              required: true,
              autoFocus: true,
              onChange: onCodeFormChange,
              value: codeState.inputValue,
              error: resetCode !== codeState.inputValue,
            }}
          />
          {/* {resetCode !== codeState.inputValue && <p style={{ color: 'red' }}>invalid code</p>} */}
        </CardBody>

        <CardFooter className={classes.cardFooter} style={{ paddingBottom: 0 }}>
          <Button
            disabled={resetCode !== codeState.inputValue}
            onClick={handleConfirmResetCode}
            color="primary"
            size="lg"
          >
            Confirm Code
          </Button>
        </CardFooter>

        <CardFooter className={classes.cardFooter}>
          <Button color="primary" size="lg" simple>
            <Link to="/landing/login">Back to Login</Link>
          </Button>
        </CardFooter>
      </>
    );
  };

  const forgotPasswordForm = () => {
    return (
      <>
        <p className={classes.divider}>Please Enter Your New Password</p>

        <CardBody>
          <CustomInput
            labelText="New Password"
            id="pass"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: 'password',
              autoComplete: 'off',
              onChange: onPassNewFormChange,
              value: passNewState.inputValue,
            }}
          />
          <CustomInput
            labelText="Confirm Password"
            id="pass"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: 'password',
              autoComplete: 'off',
              onChange: onPassConfirmFormChange,
              value: passConfirmState.inputValue,
              error: passNewState.inputValue !== passConfirmState.inputValue,
            }}
          />
          {passNewState.inputValue !== passConfirmState.inputValue && (
            <p style={{ color: 'red' }}>Password Doesn't Match</p>
          )}
        </CardBody>
        <CardFooter className={classes.cardFooter}>
          <Button onClick={handleForgotPassword} color="primary" size="lg">
            Change Password
          </Button>
        </CardFooter>

        <CardFooter className={classes.cardFooter}>
          <Button color="primary" size="lg" simple>
            <Link to="/landing/login">Back to Login</Link>
          </Button>
        </CardFooter>
      </>
    );
  };

  const { ...rest } = props;
  return (
    <div>
      <Header absolute color="primary" brand="IGCSE-ESL" rightLinks={<HeaderLinks />} fixed {...rest} />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: 'url(' + image + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card plain={false} carousel={false} className={classes[cardAnimation]}>
                <form className={classes.form}>
                  <CardHeader color="primary" className={classes.cardHeader}>
                    <h4>Forgot Password</h4>
                    <div className={classes.socialLine}>
                      <Button justIcon href="#pablo" target="_blank" color="transparent">
                        <i className={'fab fa-twitter'} />
                      </Button>
                      <Button justIcon href="#pablo" target="_blank" color="transparent">
                        <i className={'fab fa-facebook'} />
                      </Button>
                      <Button justIcon href="#pablo" target="_blank" color="transparent">
                        <i className={'fab fa-google-plus-g'} />
                      </Button>
                    </div>
                  </CardHeader>
                  {processState == 0 ? sendCodeForm() : processState == 1 ? verifyCodeForm() : forgotPasswordForm()}
                </form>
              </Card>
            </GridItem>
          </GridContainer>
          <Notification />
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
};

export default ForgotPasswordView;
