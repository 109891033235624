import * as XLSX from 'xlsx';
import { ISubtitle } from './videoNew/VideoPlayer';

export const toOptionTypeMapper = (o: { _id: string; name: string }) => ({ id: o._id, name: o.name });
export const renameKey = <OldKey extends keyof T, NewKey extends string, T extends Record<string, unknown>>(
  oldKey: OldKey,
  newKey: NewKey extends keyof T ? never : NewKey,
  userObject: T,
): Record<NewKey, T[OldKey]> & Omit<T, OldKey> => {
  const { [oldKey]: value, ...common } = userObject;

  return {
    ...common,
    ...({ [newKey]: value } as Record<NewKey, T[OldKey]>),
  };
};
export const paginateRequest = (props: {
  items: any[];
  req: Types.IRequestState;
  allLoaded: boolean;
  fetchDataAsync: Function;
  dispatch: Function;
  token: string;
  limit: number;
  q?: string;
}) => {
  const { items, req, allLoaded, limit, dispatch, token, fetchDataAsync, q } = props;
  if (req.status !== 'loading') {
    let offset = 0;
    if (items.length === 0) token && dispatch(fetchDataAsync({ token, offset, limit, q }));
    else {
      offset = items.length;
      if (!allLoaded) {
        token && dispatch(fetchDataAsync({ token, offset, limit, q }));
      }
    }
  }
};

const getStudentMarkFromAnswers = (
  answers: (
    | Types.API.Answer.IAnswerMCQModel
    | Types.API.Answer.IAnswerFillInBlanksModel
    | Types.API.Answer.IAnswerVocabModel
    | Types.API.Answer.IAnswerShortAnswersModel
    | Types.API.Answer.IAnswerNoteTakingModel
    | Types.API.Answer.IAnswerSpeakerMatchingModel
    | Types.API.Answer.IAnswerSpeakingModel
    | Types.API.Answer.IAnswerWritingModel
  )[],
): number => {
  let studentMark = 0;
  answers.forEach(subA => {
    const marksArray: number[] | number[][] = (subA as any).marks;
    if (marksArray && marksArray.length > 0) {
      marksArray.forEach((mark: number | number[]) => {
        if (typeof mark === 'number') {
          studentMark += mark;
        } else if (mark && mark.length >= 0) {
          (mark as number[]).forEach(subMark => (studentMark += subMark));
        }
      });
    }
    if ((subA as any).contentMark) studentMark += (subA as any).contentMark;
    if ((subA as any).languageMark) studentMark += (subA as any).languageMark;
  });

  return studentMark;
};

const getTotalMarkFromQuestion = (question: Types.API.Question.IQuestionModel): number => {
  let totalQuestionMark = 0;
  if (question !== null) {
    question.questions.forEach(subQ => {
      if ((subQ as any).mark) totalQuestionMark += (subQ as any).mark;
      if ((subQ as any).contentMark) totalQuestionMark += (subQ as any).contentMark;
      if ((subQ as any).languageMark) totalQuestionMark += (subQ as any).languageMark;
    });
  }
  return totalQuestionMark;
};
export const getStudentMarkFromSubAnswerModels = (answers: Types.API.Answer.IAnswerModel[]): number[] => {
  let studentMarks: number[] = [];
  answers.forEach(a => studentMarks.push(getStudentMarkFromAnswers(a.answers)));
  return studentMarks;
};
export const getTotalMarkFromSubAnswerModels = (answers: Types.API.Answer.IAnswerModel[]): number[] => {
  let totalMarks: number[] = [];
  answers.forEach(a => totalMarks.push(getTotalMarkFromQuestion(a.question)));
  return totalMarks;
};
export const getStudentMarkFromAnswerModels = (answers: Types.API.Answer.IAnswerModel[]): number => {
  let studentMark = 0;

  answers.forEach(a => (studentMark += getStudentMarkFromAnswers(a.answers)));
  return studentMark;
};

export const getTotalMarkFromAnswerModels = (answers: Types.API.Answer.IAnswerModel[]): number => {
  let totalMark = 0;

  answers.forEach(a => (totalMark += getTotalMarkFromQuestion(a.question)));
  return totalMark;
};

export const getQuizQuickReport = (
  answers: Types.API.Answer.IAnswerModel[],
): { questionTitle: string; studentMark: number; totalMark: number }[] => {
  const quickReport = answers.map(answer => {
    const questionTitle = answer.question.title;
    let studentMark = getStudentMarkFromAnswers(answer.answers);
    let totalMark = getTotalMarkFromQuestion(answer.question);
    return { questionTitle, studentMark, totalMark };
  });
  return quickReport;
};

// Month Data Format
export const sortMonths = (obj: {}) => {
  const sorted = Object.keys(obj).sort(function (a, b) {
    if (a === 'Earlier') return 1;
    if (b === 'Earlier') return -1;
    return new Date(b).getTime() - new Date(a).getTime();
  });
  return sorted;
};

export const formateMonthDataPair = (data: any, type: string) => {
  let dataCloned = data ? [...data] : [];
  return data
    ? dataCloned.reduce((groups, quiz) => {
        let month = 'Earlier';
        if (quiz.assignedDate) {
          const tempDate = new Date(quiz.assignedDate);
          month = `${tempDate.toLocaleString('default', { month: 'long' })} ${tempDate.getFullYear()}`;
        }
        if (!quiz.assignedDate && quiz.createdAt && type.startsWith('vdo')) {
          const tempDate = new Date(quiz.createdAt);
          month = `${tempDate.toLocaleString('default', { month: 'long' })} ${tempDate.getFullYear()}`;
        }

        if (!groups[month]) {
          groups[month] = [];
        }
        groups[month].push(quiz);
        return groups;
      }, {})
    : [];
};

export const filteredInfo = (idsArr: any[], infoArr: any[], forSelectOption = false, key = 'id') => {
  const result = infoArr.filter(obj => idsArr.includes(obj[key]));
  if (forSelectOption) return result.map(({ id, name }) => ({ id, name }));
  else return result;
};

const createExcelSheet = (data: any[], columns: any, studentName: string) => {
  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Create a worksheet
  const worksheet = XLSX.utils.json_to_sheet(data);

  // Set column widths based on rendered column widths
  const columnWidths = columns.map((column: any) => ({
    width: column.width / 6, // Adjust the divisor to get appropriate width
  }));

  worksheet['!cols'] = columnWidths;

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, studentName);

  // fix headers
  const headerNames = columns.map((column: any) => column.headerName);
  XLSX.utils.sheet_add_aoa(worksheet, [headerNames], { origin: 'A1' });

  // Convert the workbook to an Excel file
  const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

  return excelFile;
};

const saveExcelFile = (excelFile: any, studentName: string) => {
  const blob = new Blob([excelFile], { type: 'application/octet-stream' });
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = `${studentName}_report.xlsx`;
  document.body.appendChild(a);

  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

export const downloadStudentReport = (data: any[], columns: any, studentName: string) => {
  const excelFile = createExcelSheet(data, columns, studentName);
  saveExcelFile(excelFile, studentName);
};

export function parseSubtitle(subtitleString: string): ISubtitle[] {
  const subtitles: ISubtitle[] = [];
  const lines = subtitleString.split('\n');

  let start = 0;
  let end = 0;
  let text = '';

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i].trim();
    if (line.includes('-->')) {
      const times = line.split(' --> ');
      start = parseTime(times[0]);
      end = parseTime(times[1]);
    } else if (line && line !== 'WEBVTT' && !line.startsWith('vdocipher') && !Number(line)) {
      text = line;
      subtitles.push({ start, end, text });
    }
  }

  return subtitles;
}

function parseTime(timeString: string) {
  const [hours, minutes, seconds] = timeString.split(':');
  const timeInSeconds = parseFloat(hours) * 3600 + parseFloat(minutes) * 60 + parseFloat(seconds.replace(',', '.'));
  return timeInSeconds;
  //   return timeInSeconds * 1000;
}
